import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import MergeGames from "../../images/logo-merge-games.svg";
import Link from "../Global/Link";

// ====

function trimSlash(path) {
  return path.replace(/\/$/, "");
}

const Header = (props) => {
  const { settings } = props;
  const [menuStatus, setMenuStatus] = useState(false);
  const navRef = useRef(null);

  // Site has the pages in it!
  return (
    <>
      <header>
        <nav className="brand-background-black brand-text-white py-3 py-md-5">
          <div class="container d-flex justify-content-between align-items-center">
            <Link to="/" className="brand-logo">
              <img src={MergeGames} height="50" className="m-0 p-0" />
            </Link>
            <ul class="list-unstyled m-0 d-lg-flex flex-column align-items-start justify-content-center flex-lg-row p-3 p-md-5 p-lg-0 brand-menu">
              {settings.navigation && (
                <>
                  {settings.navigation.map((node) => {
                    return (
                      <li class="d-block d-lg-inline m-0 ms-lg-5">
                        <Link
                          to={node.link}
                          partiallyActive={node.name === "" ? false : true}
                          key={node._uid}
                          className="brand-font text-decoration-none d-block py-2 d-lg-inline py-lg-0"
                        >
                          {node.name}
                        </Link>
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
            <button
              className={`hamburger hamburger--spring d-inline d-lg-none ${
                menuStatus === true ? `is-active` : ``
              }`}
              type="button"
              onClick={() => setMenuStatus(!menuStatus)}
              aria-label="Menu"
              aria-controls="navigation"
              aria-expanded={`${menuStatus === true ? `true` : `false`}`}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </nav>
        {menuStatus === true && (
          <>
            <Helmet>
              <body className="overflow-hidden brand-menu-open" />
            </Helmet>
            <div
              className="d-block d-lg-none position-fixed top-0 end-0 bottom-0 start-0 w-100 h-100 brand-blur brand-z-1090"
              onClick={() => setMenuStatus(false)}
            />
          </>
        )}
      </header>
    </>
  );
};

export default Header;

Header.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Header.defaultProps = {
  settings: null,
};
