import React from "react";
import PropTypes from "prop-types";
import CookieConsent from "react-cookie-consent";
import SmartText from "../../utils/TextHandler";
import Link from "./Link";

// ====

const CookiesNotice = (props) => {
  const { message, mode } = props;

  const brandContainerClasses =
    "brand-background-black brand-text-white brand-link-white p-3 align-items-center row";
  const brandContentClasses =
    "col-12 col-md-8 mt-3 mb-3 mb-md-0 text-center text-md-start";
  const brandButtonWrapperClasses = "col-12 col-md-4 text-center text-md-end";

  if (mode === "preview") {
    return (
      <div className={`mx-0 ${brandContainerClasses}`}>
        <div className={brandContentClasses}>
          <SmartText>{message}</SmartText>
        </div>
        <div className={brandButtonWrapperClasses}>
          <Link button="real" icon="check">
            Okay
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Okay"
        cookieName="gdpr-google-analytics"
        disableStyles
        ButtonComponent={({ children, ...props }) => (
          <Link
            rel="nofollow noopener noreferrer"
            id="cookieBarButton"
            button="real"
            icon="check"
            colour="white"
            to
            {...props}
          >
            {children}
          </Link>
        )}
        containerClasses={`fixed-bottom brand-cookiebar ${brandContainerClasses}`}
        contentClasses={brandContentClasses}
        buttonWrapperClasses={brandButtonWrapperClasses}
        expires={150}
      >
        <SmartText>{message}</SmartText>
      </CookieConsent>
    </>
  );
};

export default CookiesNotice;

CookiesNotice.propTypes = {
  message: PropTypes.string,
  mode: PropTypes.string,
};

CookiesNotice.defaultProps = {
  message: "By using this website you consent to our use of cookies",
  mode: "normal",
};
