import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import FileHandler from "../../utils/FileHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ====

// Checks the path to see if there is a trailing slash. If not, it adds it!
function ensureTrailingSlashExists(path) {
  return path.endsWith("/") ? path : (path += "/");
}
function trimSlash(path) {
  return path.replace(/\/$/, "");
}

function ensureSlashs(path, start, end) {
  let url = path;
  if (start === true) {
    // We're adding slashes to the start
    if (url[0] !== "/") {
      url = "/" + url;
    }
  }
  if (end === true) {
    // We're adding slashes to the end
    url = url.endsWith("/") ? url : (url += "/");
  }
  return url;
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  icon,
  iconPosition,
  className,
  button,
  stretched,
  target,
  inverted,
  colour,
  subject,
  ...other
}) => {
  // Create the variables
  let href,
    type,
    window,
    css,
    iconStart,
    iconEnd,
    iconGraphic,
    emailSubject = "";

  if (to === null) {
    type = "onclick";
    href = "";
  } else {
    if (typeof to === "string") {
      // It's a string, so the href is exactly what we've asked it to be
      href = to;
      // Test if it's internal or external
      if (href.indexOf("http://") == 0 || href.indexOf("https://") == 0) {
        // External
        type = "external";
        window = "_blank";
      } else {
        // Internal
        type = "internal";
        window = "_self";
        // Ensure slashes
        href = ensureSlashs(href, true, true);
      }
    } else {
      // It's an object, so we know it's come from Storyblok
      // If it's a direct link, look for full_slug in the root...
      if (to.full_slug) {
        // It's a link straight to the story
        type = "internal";
        window = "_self";
        href = to.full_slug;
        // Ensure slashes
        href = ensureSlashs(href, true, true);
      } else if (to.linktype === "url") {
        // It is an external URL
        type = "external";
        window = "_blank";
        href = to.url;
      } else if (to.linktype === "email") {
        // It is an EMAIL!
        type = "email";
        window = "_self";
        if (subject) {
          emailSubject = "?subject=" + subject;
        }
        href = "mailto:" + to.email + emailSubject;
      } else if (to.linktype === "story") {
        // It is an internal URL
        type = "internal";
        window = "_self";
        // Get the full_slug from the story or use the cached_url value
        if (to.story?.full_slug) {
          href = to.story.full_slug;
        } else {
          href = to.cached_url;
        }
        // Ensure slashes
        href = ensureSlashs(href, true, true);
      } else if (to.linktype === "asset" || to.fieldtype === "asset") {
        // We're trying to link to an internal asset
        type = "asset";
        window = "_blank";
        href = FileHandler(to.filename) || FileHandler(to.url);
      }
    }
  }

  // Style
  css = "brand-link"; // Start with class
  if (className) {
    css += " " + className; // Add user classes
  }

  // Buttons
  if (button && button !== "false") {
    css += " btn brand-btn brand-btn-" + colour; // Add button classes
    if (inverted) {
      css += " brand-btn-inverted"; // Invert it
    }
    if (button === "real") {
      type = "button";
    }
  }

  // Stretched links
  if (stretched) {
    css += " stretched-link"; // Add stretched class
  }

  // Icons
  if (icon) {
    if (icon === true) {
      // If icon is true but not explicitly set, then set it to a default icon
      if (type === "external") {
        iconGraphic = <FontAwesomeIcon icon="external-link-alt" />;
      } else if (type === "email") {
        iconGraphic = <FontAwesomeIcon icon="paper-plane" />;
      } else if (type === "asset") {
        iconGraphic = <FontAwesomeIcon icon="external-link-alt" />;
      } else {
        iconGraphic = <FontAwesomeIcon icon="chevron-right" />;
      }
    } else {
      iconGraphic = <FontAwesomeIcon icon={icon} />;
    }
    if (iconPosition === "start") {
      iconStart = <span className={children ? "me-3" : ""}>{iconGraphic}</span>;
    } else {
      iconEnd = <span className={children ? "ms-3" : ""}>{iconGraphic}</span>;
    }
  }

  if (type === "button") {
    return (
      <button
        type="submit"
        className={css ? css : ""}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {iconStart ? iconStart : ""}
        <span className="brand-btn-text">{children}</span>
        {iconEnd ? iconEnd : ""}
      </button>
    );
  }

  if (type === "onclick") {
    return (
      <span
        className={css ? css : ""}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {iconStart ? iconStart : ""}
        {button && button !== "false" ? (
          <span className="brand-btn-text">{children}</span>
        ) : (
          children
        )}
        {iconEnd ? iconEnd : ""}
      </span>
    );
  }

  if (type === "internal") {
    return (
      <GatsbyLink
        to={href ? href : null}
        className={css ? css : ""}
        target={target || window}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {iconStart ? iconStart : ""}
        {button && button !== "false" ? (
          <span className="brand-btn-text">{children}</span>
        ) : (
          children
        )}
        {iconEnd ? iconEnd : ""}
      </GatsbyLink>
    );
  }

  return (
    <a
      href={href ? href : null}
      className={css ? css : ""}
      target={target || window}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      rel="noopener"
      {...other}
    >
      {iconStart ? iconStart : ""}
      {button && button !== "false" ? (
        <span className="brand-btn-text">{children}</span>
      ) : (
        children
      )}
      {iconEnd ? iconEnd : ""}
    </a>
  );
};

export default Link;

// ====

Link.propTypes = {
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  className: PropTypes.string,
  button: PropTypes.bool,
  stretched: PropTypes.bool,
  target: PropTypes.string,
  smooth: PropTypes.bool,
  inverted: PropTypes.bool,
  colour: PropTypes.string,
};

Link.defaultProps = {
  to: null,
  activeClassName: null,
  partiallyActive: null,
  icon: null,
  iconPosition: "end",
  className: "",
  button: false,
  stretched: false,
  target: null,
  smooth: false,
  inverted: false,
  colour: "black",
};
