module.exports = {
  siteMetadata: {
    siteUrl: "https://www.mergegames.com",
    title: "Merge Games",
  },
  plugins: [
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        name: "Merge Games",
        short_name: "Merge Games",
        description:
          "Merge Games is an independent video game publisher and distributor. Alongside its Signature Edition label, Merge publishes games for PlayStation, Nintendo and Xbox platforms.",
        homepage_url: "https://www.mergegames.com",
        start_url: "/",
        background_color: "#fff",
        theme_color: "#c00e75",
        display: "standalone",
        icon: "src/images/icon.svg",
      },
    },
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "pe99DW3YiEy4WqO92yRp3Att",
        resolveLinks: "story",
        resolve_links: "url",
        homeSlug: "home",
        resolveRelations: [
          "news_post.game",
          "link_game.game",
          "section_recruitment.vacancy",
          "game.ratings",
          "item_product.icon",
          "item_signatureedition.icon",
          "item_hero.link",
          "item_hero.link.story.content.ratings",
          "link.story.content.ratings",
          "item_rating_descriptor.icon",
        ],
        version: process.env.NODE_ENV === `production` ? `published` : `draft`,
      },
    },
    {
      resolve: `gatsby-plugin-gdpr-cookies`,
      options: {
        googleAnalytics: {
          trackingId: "YOUR_GOOGLE_ANALYTICS_TRACKING_ID", // leave empty if you want to disable the tracker
          cookieName: "gdpr-google-analytics", // default
          anonymize: true, // default
          allowAdFeatures: false, // default
        },
        googleTagManager: {
          trackingId: "", // leave empty if you want to disable the tracker
          cookieName: "gdpr-google-tagmanager", // default
          dataLayerName: "dataLayer", // default
        },
        facebookPixel: {
          pixelId: "", // leave empty if you want to disable the tracker
          cookieName: "gdpr-facebook-pixel", // default
        },
        // defines the environments where the tracking should be available  - default is ["production"]
        environments: ["production", "development"],
      },
    },
    {
      resolve: `gatsby-plugin-force-trailing-slashes`,
      options: {
        excludedPaths: [`/404.html`],
      },
    },
    {
      resolve: "gatsby-plugin-anchor-links",
      options: {
        offset: 0,
      },
    },
    {
      resolve: `gatsby-plugin-google-fonts-v2`,
      options: {
        fonts: [
          {
            family: "NTR",
          },
        ],
      },
    },
    "gatsby-plugin-sass",
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sitemap",
  ],
};
