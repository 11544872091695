export default function SmartImage(src, option, bypass = false) {
  let image = "https://a.storyblok.com/f/132233/1920x1080/6920f5c81c/404.jpg";

  if (src) {
    //const imageService = '//img2.storyblok.com/';

    /*
    let imageService = "https://otherthings.netlify.app/images/";
    if (bypass === true) {
      imageService = "https://otherthings.netlify.app/static/";
    }

    const path = src.toString().replace("https://a.storyblok.com", "");
*/
    image = src;
  }

  let size;
  if (!option) {
    size = "";
  } else {
    size = option + "/";
  }

  let imageService = "//img2.storyblok.com/";
  let path = image.replace("https://a.storyblok.com/", "");

  return imageService + size + path;
}
