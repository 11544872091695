module.exports = function FileHandler(src) {
  if (src) {
    //const fileService = "//img2.storyblok.com/";
    const fileService = "https://www.mergegames.com/files/";
    const path = src.replace("https://a.storyblok.com/", "");

    return fileService + path;
  } else {
    return null;
  }
};
