// ====================================
// Resolves Richtext fields in Storyblok
// ================

import StoryblokClient from "storyblok-js-client";
import { plugins } from "../../gatsby-config";

export default function SbResolve(content) {
  const KEY = plugins.find(
    ({ resolve }) => resolve === "gatsby-source-storyblok"
  );

  const Storyblok = new StoryblokClient({
    accessToken: KEY,
  });

  Storyblok.setComponentResolver((component, blok) => {
    let html;
    switch (component) {
      case "embed_instance":
        return `<h3><span class="d-block brand-font brand-font-uppercase brand-font-bold mb-4 text-center">${
          blok.title && blok.title
        }</span></h3><span class="${
          blok.ratio === "16x9" ? "ratio ratio-16x9 d-block" : ""
        } mb-5 mx-auto brand-richtext-embed">${blok.code && blok.code}</span>`;
        break;
      default:
        return null;
    }
  });

  const Richtext = Storyblok.richTextResolver.render(content);

  return Richtext;
}
