import React from "react";
import PropTypes from "prop-types";
import MergeGames from "../../images/logo-merge-games.svg";
import Link from "../Global/Link";
import SmartText from "../../utils/TextHandler";

function trimSlash(path) {
  return path.replace(/\/$/, "");
}

const Footer = (props) => {
  const { settings } = props;

  return (
    <footer className="w-100 py-5 small brand-background-black brand-text-white">
      <div className="container-fluid">
        <div className="row align-items-center py-3">
          <div className="col-12 text-center">
            <ul class="list-inline m-0">
              {settings.navigation && (
                <>
                  {settings.navigation.map((node) => {
                    return (
                      <li class="list-inline-item">
                        <Link
                          to={node.link}
                          partiallyActive={node.name === "" ? false : true}
                          key={node._uid}
                          className=""
                        >
                          {node.name}
                        </Link>
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
          </div>
        </div>

        <div className="row align-items-center py-3">
          <div className="col-12 text-center">
            <img src={MergeGames} height="30" className="m-0 p-0" />
          </div>
        </div>

        <div className="row align-items-center py-3">
          <div className="col-12 text-center small brand-footer-terms">
            <SmartText content={settings.footer} mode="simple" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

Footer.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Footer.defaultProps = {
  settings: null,
};
